@import 'functions';
@import 'bootstrap';
@import 'mixins';
@import 'fonts';
@import 'b2x-components';
@import 'b2x-theme/b2x-theme';

// Decommentare questo stile per debuggare i CSS.
// * {
//   background-color: rgba(green, 0.05);
// }

body {
  overflow-y: scroll;
}

.btn-outline-primary {
  @include button-outline-variant($blue-light, $white, $blue-light, $blue-light, $white);
  background: $white;
  &.disabled,
  &:disabled {
    background: $white;
  }
}

.nav-pills {
  flex-wrap: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  .nav-link {
    white-space: nowrap;
    border: 1px solid;
    border-color: $gray-300;
    &.active,
    &:hover {
      border-color: $primary;
    }
  }
}

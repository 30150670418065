@import '../css/shared';

.about-us-page {
  @include media-breakpoint-up(lg) {
    
    .content {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      
    }
}
}

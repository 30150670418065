.image-slider {
  .swiper-horizontal>.swiper-pagination-bullets {
    bottom: 0 !important;
  }

  .img-slider-col {
    position: relative;
    z-index: 10000;

    .swiper-prev,
    .swiper-next {
      position: absolute;
      top: 50%;
      z-index: 10000;

      &:disabled {
        opacity: 0.2;
      }
    }

    .img-container {
      margin-inline: auto;
      width: fit-content;
    }

    .swiper-prev {
      left: 0;
    }

    .swiper-next {
      right: 0;
    }

    .swiper-slide {
      margin-inline: auto;
      right: 0;
    }
  }
}
@import '../css/shared';

.slider-button {

  svg {
    fill: $blue;
  }

  &.swiper-button-prev {left: -20px;}
  &.swiper-button-next {right: -20px;}

  &.swiper-button-prev,
  &.swiper-button-next {
    transition: opacity 0.3s;

    &:after {
      display: none;
    }

    &.swiper-button-disabled {
      opacity: 0;
      transition: opacity 0s;
    }

  }

  @include media-breakpoint-down(lg) {
    
    &.swiper-button-prev,
    &.swiper-button-next {
      display: none !important;
    }

  }

}
@import '../css/shared';

.service-page {

  .aside-menu-title {
    text-transform: uppercase;
    font-weight: 600;
    color: #FFFFFF;
  }

  .faq-title {
    text-transform: uppercase;
    font-weight: 600;
  }

}
@import 'css/shared';

.article-modal {
  .modal-content {
    border: 0;

    .modal-header,
    .modal-footer,
    .modal-body {
      padding: 0;
    }

    .modal-header {
      @include media-breakpoint-up(lg) {
        .cs-pd {
          padding-left: 80px;
        }
      }
    }

    .modal-body {
      @include media-breakpoint-up(lg) {
        .title {
          font-weight: bold;
        }
      }
    }
    .modal-footer {
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 40px;
        background: url('images/modal-article-bg.png') no-repeat scroll center top;
        background-size: 100% 100%;
        @include media-breakpoint-up(lg) {
          height: 50px;
        }
      }
    }
  }
}

@import 'css/shared';

$fade-duraion: 200ms;

.header-checkout-desktop {
  .logo {
    img {
      width: auto;
      height: 50px;
    }
  }
}

.header-first-row-wrapper {
  .header-first-row {
    position: relative;
    //z-index: $zindex-sticky + 1;
    z-index: 4;
    background-color: $white;

    &.transparent {
      background-color: transparent;
    }

    .menu {
      .menu-item {
        border-bottom-color: transparent;
        position: relative;

        &.active {
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 3px;
            background: $primary;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }

    background-color: $white;
    transition: background-color $fade-duraion;

    &.transparent {
      background-color: transparent;
      box-shadow: none !important;
    }
  }

  .sub-menu-dropdown {
    position: absolute;
    z-index: 3;
    background-color: $white;
    opacity: 0;
    transform: translateY(-15px);
    transition: opacity 0.8s ease, transform 1s ease;

    &.mounted {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .box {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: $white;
  }
}

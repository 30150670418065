@import 'css/shared';

.row-landing {
    .cta-landing {
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
        }
    }

}
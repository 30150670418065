@import '../css/shared';

.banner-slider {
  max-width: 1920px;
  margin: 0 auto;

  .slide {
    width: 100% !important;
    padding: 0 !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    &.swiper-button-disabled {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    .swiper-pagination {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 0.625rem;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper {
      padding-bottom: 30px;
    }

    .swiper-pagination {
      top: calc(100% - 20px) !important;
      bottom: auto !important;
    }
  }
}
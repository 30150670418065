@import 'css/shared';

.best-price-modal {

    .price-container {

        .price {
            font-size: $h5-font-size;
            font-weight: 700;
        }

    }

    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-down(lg) {}

}

.ProductTile .best-price button {
    font-size: 0.75rem;
    line-height: 0.75rem;
    vertical-align: baseline;
}

.ProductPage .best-price button {

    vertical-align: baseline;
}
@import '../css/shared';

.product-page {

  .best-price {
    .Icon {
      font-size: 16px !important;
      position: relative;
      left: 5px;
    }
  }
  .absorbency {

    .drop-container {
      width: 105px;
    }

    .drop {
      display: inline-block;
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      border: 1px solid $green-light;
      border-radius: 50% 0 50% 50%;
      margin-right: 5px;

      &.drop-active {
        background: $green-light;
      }

    }

  }

  .btn-wishlist-container {z-index: 100;}

  .slider-product-page {
    .product-slide-image-container {
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
    } 
  }

  .description-text .accordion-button:focus {box-shadow: none;}

  .drop-slider {

    .swiper-pagination {
      position: relative;
      bottom: 0;
      margin-top: 30px;
    }

    .drop-item {
      position: relative;
      padding-top: 100%;
      width: 100%;
      border-radius: 0 50% 50% 50%;
      background: $green-light;
      color: $white;

      &.drop-reverse {
        border-radius: 50% 50% 50% 0; 
      }

      & > div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

    }

  }
  
  .swiper {

    .swiper-button-prev::after,
    .swiper-button-next::after {
      color: $gray-400;
      font-size: 35px;
    }

    .swiper-slide-thumb-active {
      img {
        border-color: $primary !important;
      }
    }

  }

  @include media-breakpoint-up(xl) {
    .drop-slider .swiper-pagination {display: none;}
  }

  @include media-breakpoint-up(lg) {

    .description-text   {border-radius: 0 0 0 50px;}
    .description-image  {border-radius: 0 50px 0 0; overflow: hidden;}

    .description-text .accordion-header {
      display: none;
    }

    .drop-slider .drop-item > div {
      width: 50%;
      height: 50%;
    }

  }

  @include media-breakpoint-down(lg) {
    
    .description-text .accordion-button::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }

    .drop-slider {

      .swiper-pagination {
        margin-top: 10px;
      }

      .drop-item > div {
        width: 55%;
        height: 55%;
      }

    } 

  }

}

@include media-breakpoint-down(md) {

}

.product-sticky-footer {
  .product-name {
    color: $blue;
  }
}
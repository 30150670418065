@import '../css/shared';

.landing-loyalty-page {
  .banner-landing {
      .start-50 {
          left: 85% !important;
      }   
  }

  .row-landing-loyalty {
    .cta-landing {
      @include media-breakpoint-up(lg) {
          padding: spacer(5);
      }
    }
  }

  .accordion-faq-loyalty {
    .row > [class^='col-'] {
        .accordion-item {
            border-top: 1px solid var(--bs-light);
        }
        &:first-child {
          .accordion-item {
            border-top: 0 !important;
          }
        }  
      }
      .accordion-button {
        font-weight: 700 !important;
      }
  }

  .image {
    @include media-breakpoint-up(lg) {
//      padding-left: 16rem;
    }
  }  
}
@import 'css/shared';

.mobile-navigation-offcanvas {
  .logo {
    max-width: 13.375rem;
  }
  .footer-offcanvas {
    .footer-icons {
      background-color: $turquoise;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: $white;
      padding: 6px;
    }
  }
  .footer-offcanvas-wrapper {
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 30px;
      background: url('images/bg-footer-image.png') no-repeat scroll center center transparent;
      background-size: 100% 100%;
    }
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icons/fonts/icomoon.eot?27wbv4');
  src: url('../fonts/icons/fonts/icomoon.eot?27wbv4#iefix') format('embedded-opentype'),
    url('../fonts/icons/fonts/icomoon.ttf?27wbv4') format('truetype'),
    url('../fonts/icons/fonts/icomoon.woff?27wbv4') format('woff'),
    url('../fonts/icons/fonts/icomoon.svg?27wbv4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Panton';
  src: url('../fonts/Panton/Panton-Bold.woff2') format('woff2'),
      url('../fonts/Panton/Panton-Bold.woff') format('woff'),
      url('../fonts/Panton/Panton-Bold.svg#Panton-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Panton';
  src: url('../fonts/Panton/Panton-Regular.woff2') format('woff2'),
      url('../fonts/Panton/Panton-Regular.woff') format('woff'),
      url('../fonts/Panton/Panton-Regular.svg#Panton-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Panton';
  src: url('../fonts/Panton/Panton-SemiBold.woff2') format('woff2'),
      url('../fonts/Panton/Panton-SemiBold.woff') format('woff'),
      url('../fonts/Panton/Panton-SemiBold.svg#Panton-SemiBold') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.extra-small {
    font-size: $font-size-base * 0.75;
  }
  
@import '../css/shared';

.slider-products {
 
  .swiper {
    width: 100%;
    .swiper-button-prev::after,
    .swiper-button-next::after {
      color: $gray-400;
      font-size: 35px;
    }

    .swiper-slide-thumb-active {
      img {
        border-color: $primary !important;
      }
    }

  }

 
}
@import '../css/shared';

.page-magazine {
    .list-links {
        overflow-x: auto;
        a {
            border-color: $gray-300;
            color: $gray-300;
            white-space: nowrap;
            &:focus {
                box-shadow: none;
            }
            &:hover, &:active, &.active {
                border-color: $primary;
                color: $primary;
            }
        }
    }
}

@import '../css/shared';

.show-more {
  .btn-link {
    @extend .small;
  }
}

.cart-packs {
  .cart-pack {
    .product-name {
      color: $blue;
      font-weight: 600;
    }
    .text-offer {
      color: $promo;
    }
  }
}

.form-group .form-label.required:after {
  color: $promo;
  content: '*';
  margin-left: 0.25rem;
}

@import '../css/shared';

.article-page {
    .article-content {
        h2 {
            margin: 20px 0;
            font-size: $h5-font-size;
            @include media-breakpoint-up(md) {
                font-size: $h4-font-size;
            }
        }
        h3 {
            font-size: $h5-font-size;
            margin: 20px 0;
        }
        span {
           font-size: $h6-font-size !important;
        }
        img {
            border-radius: 6px;
            max-width: 100%;
            height: auto;
            float: none;
            margin: 20px auto;
            @include media-breakpoint-up(md) {
                margin: 0 20px 20px 0;
                float: left;
            }
        }
        ul {
            padding: 0;
            > li {
                display: block;
                margin: 10px 0;
                position: relative;
               // padding-left: 20px;
                &:before {
                    content: "";
                    height: 5px;
                    margin: 0 5px;
                    width: 5px;
                    background-color: var(--bs-secondary);
                    border-radius: 50%;
                    display: inline-block;
                    margin-bottom: 3px;
                }
            }
        }
    }

}

@include media-breakpoint-down(md) {

}
@import 'css/shared';

.Breadcrumb {

  .breadcrumb-item {

    &:before {
      font-size: 10px;
      margin: 0;
    }
  }
}

@import '../css/shared';

.search-page {

  .order-dropdown {

    button:after {display: none;}

  }

  .search-filters {

    .search-result {background: $gray-100;}

    .text-primary {color: $blue !important;}

    .accordion {

      .accordion-header  {

        &:not(.main-category) {font-weight: bold;}
        .btn {color: $black !important;}

      }
      
    }

  }

  @include media-breakpoint-up(lg) {
    
    .show-more {

      .content {
        max-height: 300px;
        transition: max-height 0.5s ease-in-out;
        
        &.show-more-active {
          max-height: 500px;
          overflow-y: scroll;
        }
      
      }

    }

  }

}
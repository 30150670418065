@import 'css/shared';

.product-page {

  .price-block {

    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-down(sm) {}

  }
 
}

.product-sticky-footer {

  .price-block {

    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-down(sm) {
      .discount-percentage {display: none !important;}
    }

  }
 
}

.product-tile {

  .price-block {

    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-down(sm) {}

  }
  
}

@import 'css/shared';

$height-bg-xs: 30px;
$height-bg-lg: 100px;
$height-bg-xl: 220px;

.Page {
  padding-bottom: $height-bg-xs;
  @include media-breakpoint-up(lg) {
    padding-bottom: $height-bg-lg;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: $height-bg-xl;
  }
}

.footer {
  position: relative;
  margin-top: -$height-bg-xs;
  @include media-breakpoint-up(lg) {
    margin-top: -$height-bg-lg;
  }
  @include media-breakpoint-up(xl) {
    margin-top: -$height-bg-xl;
  }
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: $height-bg-xs;
    background: url('images/bg-footer-image.png') no-repeat scroll center center transparent;
    background-size: 100% 100%;
    @include media-breakpoint-up(lg) {
      height: $height-bg-lg;
    }
    @include media-breakpoint-up(xl) {
      height: $height-bg-xl;
    }
  }

  .accordion .row > [class^='col-'] .accordion-item {
    border-bottom: 0 !important;
  }

  @include media-breakpoint-up(lg) {
  }

  @include media-breakpoint-down(lg) {
    .footer-social {
      border-top: 1px solid $white;
    }

    .container-xxl {
      padding: 0;
    }
    .accordion {
      .accordion-item {
        border-style: solid;
        border-width: 1px 0 0 0;
        border-color: $white;
        border-radius: 0;
      }

      .accordion-header button {
        box-shadow: none;

        &:after {
          background: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
        }
      }

      .row {
        margin: 0;

        & > div {
          padding: 0;
        }
      }
    }
  }

  .assistance {
    background-color: $turquoise;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: $white;
    padding: 6px;
  }
}

@import 'css/shared';

.products-landing {
    
    .products-block {
        text-align: center;
        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }

}
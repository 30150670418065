@import '../css/shared';

.landing-template-e-page {
  .absorbency {

    .drop-container {
      width: 105px;
    }

    .drop {
      display: inline-block;
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      border: 1px solid $green-light;
      border-radius: 50% 0 50% 50%;
      margin-right: 5px;

      &.drop-active {
        background: $green-light;
      }

    }

  }
}
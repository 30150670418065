@import '~bootstrap/scss/functions';
@import 'variables';

// $grid-gutter-width: 2rem;

$enable-negative-margins: true;

$custom-container-max-widths: (
  xxl: 1434px,
);

$font-family-sans-serif: 'Panton', sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 3.75;
$h2-font-size: $font-size-base * 2.5;
$h3-font-size: $font-size-base * 1.875;
$h4-font-size: $font-size-base * 1.625;
$h5-font-size: $font-size-base * 1.375;

$promo: #ff2954;
$black: #000;
$white: #fff;
$gray-100: #f5f5f5; // light
$gray-200: #e0e0e0;
$gray-300: #b5b5b5;
$gray-400: #787878;
$blue: #001689;
$blue-light: #0045FF;
$yellow: #FFB116;
$turquoise: #00D8B4;
$violet: #7F49DB;
$violet-light: #E2DBEE;
$green: #7ABC10;
$green-light: #C6C61D;
$yellow-light: #F4DE67;

$primary: $blue-light;
$secondary: $turquoise;
$light: $gray-200;

$body-bg: $white;
$body-color: $black;

$lighter: $gray-200;

$custom-colors: (
  'promo': $promo,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'yellow': $yellow,
  'turquoise': $turquoise,
  'violet': $violet,
  'violet-light': $violet-light,
  'yellow-light': $yellow-light,
  'blue': $blue,
  'blue-light': $blue-light,
  'green': $green,
  'green-light': $green-light,
);

$modal-header-border-width: 0;
$modal-footer-border-width: 0;

$offcanvas-horizontal-width: 500px;
$offcanvas-padding-x: 3rem;
$offcanvas-border-width: 0;

$enable-rounded: false;
$border-radius: 10px;
$border-radius-sm: 5px;
$border-radius-lg: 15px;

$btn-font-weight: 700;
$line-height-base: 1.625;
$input-border-color: $gray-200;
$border-color: $gray-200;

$btn-box-shadow: none;
$btn-active-box-shadow: none;
$btn-focus-box-shadow: none;

@import '../../node_modules/bootstrap/scss/variables';
//@import '/node_modules/bootstrap/scss/utilities';

$container-max-widths: map-merge($container-max-widths, $custom-container-max-widths);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-text: map-merge($utilities-text, $custom-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');
$utilities-bg: map-merge($utilities-bg, $custom-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');

.zindex-dropdown {
  z-index: $zindex-dropdown;
}

.zindex-sticky {
  z-index: $zindex-sticky;
}

.zindex-fixed {
  z-index: $zindex-fixed;
}

.zindex-modal-backdrop {
  z-index: $zindex-modal-backdrop;
}

.zindex-modal {
  z-index: $zindex-modal;
}

.zindex-popover {
  z-index: $zindex-popover;
}

.zindex-tooltip {
  z-index: $zindex-tooltip;
}

$nav-link-font-weight: 700;
$nav-link-color: $gray-300;
$nav-link-hover-color: $primary;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: $white;

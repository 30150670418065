@import 'css/shared';

.product-tile {
 
  .bg-promo {
    display: inline-block;
    text-align: center;
    height: 40px;
    width: 40px;
    line-height: 40px !important;
  }
  

  .product-tile-basic-info {
    .best-price {
      .Icon {
        font-size: 14px !important;
        position: relative;
      }
    }
    .absorbency {

      .drop-container {
        width: 60px;
      }

      .drop {
        display: inline-block;
        width: 10px;
        height: 10px;
        box-sizing: border-box;
        border: 1px solid $green-light;
        border-radius: 50% 0 50% 50%;
        margin-right: 2px;
        margin-bottom: 2px;
  
        &.drop-active {
          background: $green-light;
        }
  
      }
  
    }
    
  }

  .product-tile-extra-info {

    .add-to-cart-button  {
      width: 35px;
      height: 35px;

      @include media-breakpoint-down(lg) {
        width: 25px;
        height: 25px;
      }

    }

  }

  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-down(lg) {}
  
}

@import '../css/shared';

.product-category-slider {
    .category-slide {
        .category-link {
            top: 85%;
            a {
                line-height: 1;
            }
            
        }
    }

    .swiper-pagination {
        position: relative;
        margin-top: .625rem;
    }

    .swiper-horizontal > .swiper-pagination-bullets, 
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: auto;
    }

    // @include media-breakpoint-up(lg) {
    //     .swiper-wrapper {
    //         justify-content: center;
    //     }
    // }
}
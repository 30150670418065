@import '../css/shared';

.landing-template-c-page {
    
.swiper-products {
    .swiper-wrapper {
      @include media-breakpoint-up(lg) {
        justify-content: center;
      }
    }
  }

}
@import '../css/shared';

.slider-vertical {
  width: 100%;

  &.swiper {
    height: 40px;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-pagination {
      position: absolute;
      left: 0;
      top: auto;
      right: 0;
      bottom: 3px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: none;

      .swiper-pagination-bullet {
        display: block;
        margin: 0 3px;
        width: 6px;
        height: 6px;
      }

      .swiper-pagination-bullet {
        background-color: var(--bs-white);
        opacity: 0.7;
      }

      .swiper-pagination-bullet-active {
        background-color: var(--bs-white);
        opacity: 1;
      }
    }
  }
}

@import '../css/shared';

.cart-page {

  .cart-packs {

    .cart-pack {
      & > .cart-sku:first-child > div {padding-top: 0 !important;}
      & > .cart-sku:last-child > div  {padding-bottom: 0 !important;}
      .cart-sku {
        .SeparatedList {
          .btn {
            font-weight: 300;
            color: $gray-400;
          }
        }
        @include media-breakpoint-up(lg) {
          .cart-sku-action {
            padding: map-get($spacers, 3) 0 !important;

            .cart-sku-price {font-size: $h6-font-size;}

            & > div:first-child {

              & > .row > .col {
                width: 100%;
                display: flex;
                justify-content: flex-end;
  
                &:first-child {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .cart-to-free-shipping {
    background: $secondary;
    font-weight: 700;
  }


  .generic-coupon-form {
    input:focus + .btn {border-color: $primary !important}
    input.is-invalid + .btn {border-color: $red !important}
  }

  #form-cartGenericCoupon {
    input {
      border-color: var(--bs-dark) !important;
    }

    button {
      z-index: 100;
      opacity: 1 !important;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: calc(100% - 14px);
        background: var(--bs-dark);
      }
    }
  }

  .cart-summary {

    h6 {font-weight: 700;}

  }

}
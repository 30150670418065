@import '../css/shared';

.account-page {
   .title {
    color: $blue;
  }
  .menu-account {

    .list-group {
        .list-group-item {
          .Icon {
            color: $blue;
          }
          &.active {
                color: $white !important;
                .Icon { 
                    color: $white !important;
                }
            }
        }
    }
  }

  .tax-benefits-form {
    
    label.form-label,
    .btn-documentation {
      font-weight: 700;
    }
    
  }

  .order-tracking-url, .order-invoice-download {
    font-weight: 700;
  }

}
@import './css/shared';

.banner-component {
  position: relative;

  .message {
    padding-left: 12px;
    padding-right: 12px;
  }

  @include media-breakpoint-up(md) {
    .title {
      line-height: 1;
    }

    .message {
      position: absolute;
      //      z-index: 100;
      bottom: 0;
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    .message {
      text-align: center !important;
    }

    .title {
      font-size: 1.625rem;
      color: $blue;
    }

    .description {
      font-size: 1.125rem;
    }

    .description,
    .disclaimer {
      color: $black !important;
    }
  }
}